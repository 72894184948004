import axios from 'axios';
import {
  closeLoading,
  handleErrorResponse,
  showError,
  showLoading,
} from '@/components/widgets/swalUtils';

export const state = {
  // Active Checklist (and items) for this client
  clientChecklists: null,
  clientChecklistItems: null,
  // Incident Checklists
  currentIncidentChecklists: null,
  newIncidentChecklists: null,
};

export const getters = {
  getClientChecklists(state) {
    return state.clientChecklists;
  },
  getClientChecklistItems(state) {
    return state.clientChecklistItems;
  },
  getCurrentIncidentChecklists(state) {
    return state.currentIncidentChecklists;
  },
  getNewIncidentChecklists(state) {
    return state.newIncidentChecklists;
  },
};

export const mutations = {
  SET_CLIENT_CHECKLISTS(state, payload) {
    state.clientChecklists = payload;
  },
  SET_CLIENT_CHECKLIST_ITEMS(state, payload) {
    state.clientChecklistItems = payload;
  },
  SET_CURRENT_INCIDENT_CHECKLISTS(state, payload) {
    state.currentIncidentChecklists = payload;
  },
  SET_NEW_INCIDENT_CHECKLISTS(state, payload) {
    state.newIncidentChecklists = payload;
  },
};

export const actions = {
  async loadClientChecklists({ commit }, { user } = {}) {
    const { client_id } = user;
    let activeClientChecklists;
    let activeClientChecklistItems;
    if (client_id) {
      // Gets all active checklists for a given client
      await axios.get(`/api/json/v1/client/${client_id}/checklists?limit=50`)
        .then((response) => {
          if (response.status === 200) {
            activeClientChecklists = response.data.checklists;
          } else {
            showError();
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });

      // Gets all active checklist Items for a given client
      await axios.get(`/api/json/v1/client/${client_id}/checklistitems`)
        .then((response) => {
          if (response.status === 200) {
            activeClientChecklistItems = response.data.checklist_items;
            commit('SET_CLIENT_CHECKLIST_ITEMS', activeClientChecklistItems);
          } else {
            showError();
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      await showError();
    }

    // Sort checklists by their sort order
    activeClientChecklists.sort((a, b) => (a.sort_order - b.sort_order));
    // Push items into checklists
    activeClientChecklists.forEach((checklist) => {
      checklist.items = [];
      activeClientChecklistItems.forEach((item) => {
        if (item.checklist_id === checklist.id) {
          checklist.items.push(item);
        }
      });
    });
    // Sort items by their sort order
    activeClientChecklists.forEach((checklist) => {
      checklist.items.sort((a, b) => (a.sort_order - b.sort_order));
    });
    commit('SET_CLIENT_CHECKLISTS', activeClientChecklists);
  },

  async updateIncidentChecklists({ state, commit, dispatch }, { user, incident_id } = {}) {
    const { client_id } = user;

    showLoading('Updating Checklists...');

    // Filter out checklists to assign and unassign to/from incident
    const toAssign = state.newIncidentChecklists
      .filter((x) => !state.currentIncidentChecklists.includes(x));
    const toUnassign = state.currentIncidentChecklists
      .filter((x) => !state.newIncidentChecklists.includes(x));

    const requestsArray = [];
    // Push Assign promises to array
    toAssign.forEach((checklist_id) => {
      const patchURL = `/api/json/v1/client/${client_id}/checklists/${checklist_id}`
        + `/assign/incident/${incident_id}`;
      requestsArray.push(axios.patch(patchURL));
    });

    // Push Unssign promises to array
    toUnassign.forEach((checklist_id) => {
      const deleteURL = `/api/json/v1/client/${client_id}/checklists/${checklist_id}`
        + `/assign/incident/${incident_id}`;
      requestsArray.push(axios.delete(deleteURL));
    });

    Promise
      .all(requestsArray)
      .then(axios.spread((...responses) => {
        responses.forEach((response) => {
          if (response.status !== 200) {
            showError();
            console.log('Response: ', response);
          }
        });
        // Update store Current checklists with new Checklists
        commit('SET_CURRENT_INCIDENT_CHECKLISTS', state.newIncidentChecklists);
        // Reload incident with new checklists attached
        dispatch('incidents/loadIncident', { user, incident_id }, { root: true });
        closeLoading();
      }))
      .catch((error) => {
        handleErrorResponse(error);
        console.log('Error: ', error);
        console.log('Error Response: ', error.response);
      });
  },

  saveCurrentIncidentChecklists({ commit }, payload) {
    commit('SET_CURRENT_INCIDENT_CHECKLISTS', payload);
  },

  saveNewIncidentChecklists({ commit }, payload) {
    commit('SET_NEW_INCIDENT_CHECKLISTS', payload);
  },
};
