import axios from 'axios';
import {
  showError, handleErrorResponse,
  showLoading, showMessage,
} from '@/components/widgets/swalUtils';

export const state = {
  loading: false,
  taskTypes: [],
};

export const getters = {
  getLoadingTaskTypeStatus(state) {
    return state.loading;
  },
  getTaskTypes(state) {
    return state.taskTypes;
  },
};

export const mutations = {
  SWITCH_LOADING_STATUS(state) {
    state.loading = !state.loading;
  },
  SET_TASK_TYPES(state, payload) {
    Object.keys(payload).forEach(
      (index) => state.taskTypes.push(payload[index]),
    );
  },
};

export const actions = {
  async createTaskType({ commit }, { taskType, user } = {}) {
    showLoading({ title: 'Saving Task Type...' });
    const { client_id } = user;
    const { name, icon } = taskType;
    await axios.post(`/api/json/v1/client/${client_id}/task_types`, {
      name, icon,
    }).then((response) => {
      commit('SET_TASK_TYPES', [{ name, icon, id: response.data.task_type_id }]);
      showMessage({ title: '', timer: 1000 });
      return Promise.resolve(true);
    })
      .catch((error) => {
        handleErrorResponse(error);
        return Promise.resolve(false);
      });
  },
  async loadOffsetTaskTypes({ commit, state, dispatch }, { user, offset } = {}) {
    offset = !offset ? 50 : offset += 50;

    if (!state.loading) {
      commit('SWITCH_LOADING_STATUS');
    }

    if (state.taskTypes?.length === offset) {
      const { client_id } = user;
      if (client_id) {
        axios.get(`/api/json/v1/client/${client_id}/task_types?offset=${offset}`)
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.task_types) {
                commit('SET_TASK_TYPES', response.data.task_types);
                dispatch('loadOffsetTaskTypes', {
                  user, offset,
                });
              }
            } else {
              showError('Unable to load task types!');
              console.log('Response: ', response);
            }
          })
          .catch((error) => {
            handleErrorResponse(error);
          });
      } else {
        showError('Client not found!');
      }
    } else {
      commit('SWITCH_LOADING_STATUS');
    }
  },
  async loadTaskTypes({ state, commit, dispatch }, { user, loadFull } = {}) {
    if (!state.loading) {
      commit('SWITCH_LOADING_STATUS');
    }

    const { client_id } = user;
    if (client_id) {
      axios.get(`/api/json/v1/client/${client_id}/task_types`)
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.task_types) {
              commit('SET_TASK_TYPES', response.data.task_types);
              if (loadFull) {
                dispatch('loadOffsetTaskTypes', { user });
              } else {
                commit('SWITCH_LOADING_STATUS');
              }
            }
          } else {
            showError('Unable to load task types!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
      commit('SWITCH_LOADING_STATUS');
    }
  },
};
