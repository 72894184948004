import axios from 'axios';
import {
  handleErrorResponse,
  showError,
} from '@/components/widgets/swalUtils';

export const state = {
  incidentNotes: null,
};

export const getters = {
  getIncidentNotes(state) {
    return state.incidentNotes;
  },
};

export const mutations = {
  SET_INCIDENT_NOTES(state, payload) {
    state.incidentNotes = payload;
  },
};

export const actions = {
  async loadIncidentNotes({ commit }, { user, incident_id } = {}) {
    const { client_id } = user;
    if (client_id) {
      // Get Notes related to this Incident
      await axios.get(`/api/json/v1/client/${client_id}/notes?incident_id=${incident_id}`)
        .then((response) => {
          if (response.status === 200) {
            const { notes } = response.data;
            // console.log('response: ', response.data.notes);
            notes.sort((a, b) => a.id - b.id);
            commit('SET_INCIDENT_NOTES', notes);
          } else {
            showError('Unable to load notes!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
    }
  },
};
