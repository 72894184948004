export const allowedFilesToUpload = [
  /*
  // Document MIME Types
  */
  {
    mimeType: 'application/msword',
    extension: 'doc',
    kind: 'MS Word Document',
    header: 'd0cf11e0',
  },
  {
    mimeType: 'application/pdf',
    extension: 'pdf',
    kind: 'PDF Document',
    header: '25504446',
  },
  {
    mimeType: 'application/rtf',
    extension: 'rtf',
    kind: 'Rich Text Format',
    header: '7b5c7274',
  },
  {
    mimeType: 'application/vnd.ms-excel',
    extension: 'xls',
    kind: 'MS Excel Spreadsheet',
    header: 'd0cf11e0',
  },
  {
    mimeType: 'application/vnd.ms-powerpoint',
    extension: 'ppt',
    kind: 'MS PowerPoint Presentation',
    header: 'd0cf11e0',
  },
  {
    mimeType: 'application/vnd.oasis.opendocument.formula',
    extension: 'odf',
    kind: 'OpenDocument Formula Document',
    header: '504b0304',
  },
  {
    mimeType: 'application/vnd.oasis.opendocument.presentation',
    extension: 'odp',
    kind: 'OpenDocument Presentation',
    header: '504b0304',
  },
  {
    mimeType: 'application/vnd.oasis.opendocument.spreadsheet',
    extension: 'ods',
    kind: 'OpenDocument Spreadsheet',
    header: '504b0304',
  },
  {
    mimeType: 'application/vnd.oasis.opendocument.text',
    extension: 'odt',
    kind: 'OpenDocument Text Document',
    header: '504b0304',
  },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    extension: 'pptx',
    kind: 'MS PowerPoint Presentation',
    header: '504b0304',
  },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx',
    kind: 'MS Excel Spreadsheet',
    header: '504b0304',
  },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extension: 'docx',
    kind: 'MS Word Document',
    header: '504b0304',
  },
  {
    mimeType: 'application/vnd.visio',
    extension: 'vsd',
    kind: 'MS Visio File',
    header: 'd0cf11e0',
  },
  /*
  // Audio MIME Types
  */
  {
    mimeType: 'audio/3gpp2',
    extension: '3g2',
    kind: '3GPP2 Audio Video',
    header: '0000001c',
  },
  {
    mimeType: 'audio/3gpp',
    extension: '3gp',
    kind: '3GPP Audio Video',
    header: '0000001c',
  },
  {
    mimeType: 'audio/aac',
    extension: 'aac',
    kind: 'AAC audio',
    header: 'fff15080',
  },
  {
    mimeType: 'audio/mpeg',
    extension: 'mp3',
    kind: 'MP3 audio',
    header: '494433', // 49443303, 49443304
  },
  {
    mimeType: 'audio/ogg',
    extension: 'oga',
    kind: 'OGG Audio',
    header: '4f676753',
  },
  {
    mimeType: 'audio/opus',
    extension: 'opus',
    kind: 'Opus Audio',
    header: '4f676753',
  },
  {
    mimeType: 'audio/wav',
    extension: 'wav',
    kind: 'WAV Audio',
    header: '52494646',
  },
  {
    mimeType: 'audio/webm',
    extension: 'weba',
    kind: 'WEBM Audio',
    header: '1a45dfa3', // Check this header
  },
  /*
  // Image MIME Types
  */
  {
    mimeType: 'image/bmp',
    extension: 'bmp',
    kind: 'BMP Image',
    header: '424d',
  },
  {
    mimeType: 'image/gif',
    extension: 'gif',
    kind: 'GIF Image',
    header: '47494638',
  },
  {
    mimeType: 'image/jpeg',
    extension: 'jpg',
    kind: 'JPEG Image',
    header: 'ffd8ffe0',
  },
  {
    mimeType: 'image/jpeg',
    extension: 'jpeg',
    kind: 'JPEG Image',
    header: 'ffd8ffe0',
  },
  {
    mimeType: 'image/png',
    extension: 'png',
    kind: 'PNG Image',
    header: '89504e47',
  },
  {
    mimeType: 'image/svg+xml',
    extension: 'svg',
    kind: 'SVG Image',
    header: '3c3f786d',
  },
  {
    mimeType: 'image/tiff',
    extension: 'tiff',
    kind: 'TIFF Image',
    header: '4d4d002a',
  },
  {
    mimeType: 'image/tiff',
    extension: 'tif',
    kind: 'TIFF Image',
    header: '49492a00',
  },
  {
    mimeType: 'image/webp',
    extension: 'webp',
    kind: 'WEBP Image',
    header: '52494646',
  },
  /*
  // Text MIME Types
  */
  {
    mimeType: 'text/csv',
    extension: 'csv',
    kind: 'CSV File',
    header: '', //  NO HEADER PATTERN
  },
  {
    mimeType: 'text/plain',
    extension: 'txt',
    kind: 'Text File',
    header: '', //  NO HEADER PATTERN
  },
  {
    mimeType: 'text/rtf',
    extension: 'rtf',
    kind: 'Rich Text Format',
    header: '7b5c7274',
  },
  /*
  // Video MIME Types
  */
  {
    mimeType: 'video/3gpp',
    extension: '3gp',
    kind: '3GPP Audio Video',
    header: '0000001c', // 0000001c, 00000020
  },
  {
    mimeType: 'video/3gpp2',
    extension: '3g2',
    kind: '3GPP2 Audio Video',
    header: '0000001c',
  },
  {
    mimeType: 'video/mp4',
    extension: 'mp4',
    kind: 'MP4 video',
    header: '00000020',
  },
  {
    mimeType: 'video/mpeg',
    extension: 'mpeg',
    kind: 'MPEG Video',
    header: '000001ba',
  },
  {
    mimeType: 'video/ogg',
    extension: 'ogv',
    kind: 'OGG Video',
    header: '4f676753',
  },
  {
    mimeType: 'video/quicktime',
    extension: 'qt',
    kind: 'Quicktime Video',
    header: '00000014', // From mov files
  },
  {
    mimeType: 'video/quicktime',
    extension: 'mov',
    kind: 'Quicktime Video',
    header: '00000014',
  },
  {
    mimeType: 'video/webm',
    extension: 'webm',
    kind: 'WEBM Video',
    header: '1a45dfa3',
  },
  {
    mimeType: 'video/x-matroska',
    extension: 'mkv',
    kind: 'Matroska Video',
    header: '1a45dfa3',
  },
  {
    mimeType: 'video/x-msvideo',
    extension: 'avi',
    kind: 'AVI Audio Video',
    header: '52494646',
  },
];

export const browserViewableFiles = [
  'gif',
  'jpeg',
  'jpg',
  'png',
  'bmp',
];

export const noHeaderFileTypes = [
  'csv',
  'txt',
];
