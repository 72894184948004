import axios from 'axios';
import { showLoading, closeLoading, showMessage } from '@/components/widgets/swalUtils';

export const state = {
  currentLandmarks: [],
};

export const mutations = {
  SET_CURRENT_LANDMARKS(state, payload) {
    Object.keys(payload).forEach((index) => {
      state.currentLandmarks.push(payload[index]);
    });
  },
  CLEAR_LANDMARKS(state) {
    state.currentLandmarks = [];
  },
};
export const getters = {
  getCurrentLandmarks(state) {
    return state.currentLandmarks;
  },
};

async function requestValidator(request) {
  try {
    const { landmark_id } = request.data;
    return !!landmark_id;
  } catch (e) {
    console.log('Landmark error: ', e.message);
    throw new Error(e.message);
  }
}
export const actions = {
  async createLandmark(
    { state },
    {
      form, user, tool,
    } = {},
  ) {
    const { client_id } = user;
    if (form.id) {
      const { _latlng } = tool.target;
      try {
        showLoading('Editing Landmark');
        const updateLandmark = await axios.patch(
          `/api/json/v1/client/${client_id}/landmarks/${form.id}`,
          {
            latitude: _latlng.lat,
            longitude: _latlng.lng,
            name: form.name,
            notes: form.notes,
            icon: form.icon,
            layer_id: form.layer_id,
          },
        );
        await requestValidator(
          updateLandmark,
        );
        showMessage({
          title: 'Landmark Updated!',
          icon: 'success',
        });
        return Promise.resolve(true);
      } catch (error) {
        console.log(state);
        showMessage({
          title: 'Error updating',
          text: error.message,
          icon: 'error',
        });
        return Promise.resolve(false);
      }
    } else {
      try {
        showLoading('Creating Landmark');
        const { _latlng } = tool.layer;
        const createLandmark = await axios.post(
          `/api/json/v1/client/${client_id}/layer/${form.layer_id}/landmarks`,
          {
            layer_id: form.layer_id,
            icon: form.icon,
            latitude: _latlng.lat,
            longitude: _latlng.lng,
            name: form.name,
            notes: form.notes,
            // TODO: location: '',
            date: form.date,
          },
        );
        await requestValidator(
          createLandmark,
        );
        showMessage({
          title: 'Landmark Created!',
          icon: 'success',
        });
        return Promise.resolve(true);
      } catch (error) {
        console.log(state);
        showMessage({
          title: 'Error saving landmark',
          text: error.message,
          icon: 'error',
        });
        return Promise.resolve(false);
      }
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteLandmark({}, { form, user } = {}) {
    showLoading('Deleting Landmark');
    try {
      const { id } = form;
      const { client_id } = user;
      const deleteLandmark = await axios.delete(
        `/api/json/v1/client/${
          client_id
        }/landmarks/${id}`,
      );
      if (deleteLandmark?.data) {
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    } catch (err) {
      return Promise.resolve(true);
    }
  },
  async loadLandmarks({ commit }, {
    user, layers,
  } = {}) {
    showLoading('Loading landmarks');
    try {
      commit('CLEAR_LANDMARKS');
      const { client_id } = user;
      for (let index = 0; layers?.length > index; index += 1) {
        if (layers[index].id) {
          const { id } = layers[index];
          // eslint-disable-next-line no-await-in-loop
          const landmark = await axios.get(
            `/api/json/v1/client/${
              client_id
            }/landmarks?layer_id=${id}`,
          );
          if (landmark?.data) {
            const { landmarks } = landmark.data;
            commit('SET_CURRENT_LANDMARKS', landmarks);
          }
        }
      }
      closeLoading();
      return Promise.resolve(true);
    } catch (err) {
      console.log(err.message);
      showMessage({
        title: 'Error',
        text: 'Not able to get the landmarks.',
        icon: 'error',
      });
      return Promise.resolve(false);
    }
  },
};
