import axios from 'axios';
import {
  closeLoading,
  showLoading,
  showMessage,
} from '@/components/widgets/swalUtils';

function saveState(key, state) {
  localStorage.setItem(key, JSON.stringify(state));
}

export const state = {
  currentUser: localStorage.getItem('authUser'),
};

export const mutations = {
  SET_AUTH_USER(state, newValue) {
    if (!newValue) {
      localStorage.removeItem('authUser');
      state.currentUser = localStorage.getItem('authUser');
    } else {
      state.currentUser = newValue;
      saveState('authUser', newValue);
    }
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
};

export const actions = {

  // Logs in the current user.
  async logIn({ commit, dispatch, getters }, { user_name, password } = {}) {
    if (getters.loggedIn) {
      closeLoading();
      return dispatch('validate');
    }

    const login = await axios.post('auth/login', {
      user_name,
      password,
    });

    if (login.data?.message) {
      showMessage({
        title: 'Login failed',
        text: login.data.message,
        icon: 'error',
      });
    }
    if (login.data?.user) {
      const { user } = login.data;
      commit('SET_AUTH_USER', user);

      showMessage({
        title: 'Success',
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
      });
      return Promise.resolve(user);
    }
    return Promise.resolve(null);
  },

  // Logs out the current user.
  logOut({ commit }) {
    commit('SET_AUTH_USER', null);
  },

  // register the user
  resetPassword({ dispatch, getters }) {
    if (getters.loggedIn) return dispatch('validate');
    return false;
  },

  // Validates the current user's
  async validate({ state, dispatch }) {
    showLoading('Loading...');
    if (!state.currentUser) {
      await dispatch('logOut');
      closeLoading();
      return Promise.resolve(false);
    }
    const { client_id } = JSON.parse(state.currentUser);
    if (client_id) {
      const auth = await axios.get(`/api/json/v1/client/${client_id}/status`, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).catch(async (err) => {
        console.log(err);
        await dispatch('logOut');
        closeLoading();
        return Promise.resolve(false);
      });
      if (auth?.data) {
        closeLoading();
        return Promise.resolve(state.currentUser);
      }
    }
    await dispatch('logOut');
    closeLoading();
    return Promise.resolve(false);
  },
};
