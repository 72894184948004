import axios from 'axios';
import {
  handleOkResponse,
  handleErrorResponse,
  showError,
  showLoading,
} from '@/components/widgets/swalUtils';

export const state = {
  currentIncident: null,
  activeIncidents: null,
  preplanIncidents: null,
  archivedIncidents: null,
};

export const getters = {
  getCurrentIncident(state) {
    return state.currentIncident;
  },
  getActiveIncidents(state) {
    return state.activeIncidents;
  },
  getPreplanIncidents(state) {
    return state.preplanIncidents;
  },
  getArchivedIncidents(state) {
    return state.archivedIncidents;
  },
  getActiveIncidentsPerPeriod(state) {
    if (state.activeIncidents) {
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      const response = {
        name: 'Active Incidents',
        data: [],
      };
      for (let a = 0; a <= months.length; a += 1) {
        const date = new Date();
        date.setMonth(a);

        const founded = state.activeIncidents.filter((item) => {
          const incidentDate = new Date(item.date);
          if (incidentDate.getMonth() === date.getMonth()) {
            return item;
          }
          return false;
        });

        if (founded.length) {
          response.data.push(founded.length.toString());
        } else {
          response.data.push('0');
        }
      }
      return response;
    }

    return [];
  },
  getArchivedIncidentsPerPeriod(state) {
    if (state.archivedIncidents) {
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      const response = {
        name: 'Archived Incidents',
        data: [],
      };
      for (let a = 0; a <= months.length; a += 1) {
        const date = new Date();
        date.setMonth(a);

        const founded = state.archivedIncidents.filter((item) => {
          const incidentDate = new Date(item.date);
          if (incidentDate.getMonth() === date.getMonth()) {
            return item;
          }
          return false;
        });

        if (founded.length) {
          response.data.push(founded.length.toString());
        } else {
          response.data.push('0');
        }
      }
      return response;
    }

    return [];
  },
  getPreplanIncidentsPerPeriod(state) {
    if (state.preplanIncidents) {
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      const response = {
        name: 'Preplan Incidents',
        data: [],
      };
      for (let a = 0; a <= months.length; a += 1) {
        const date = new Date();
        date.setMonth(a);

        const founded = state.preplanIncidents.filter((item) => {
          const incidentDate = new Date(item.date);
          if (incidentDate.getMonth() === date.getMonth()) {
            return item;
          }
          return false;
        });

        if (founded.length) {
          response.data.push(founded.length.toString());
        } else {
          response.data.push('0');
        }
      }
      return response;
    }

    return [];
  },
};

export const mutations = {
  CURRENT_INCIDENT(state, payload) {
    state.currentIncident = payload;
  },
  ACTIVE_INCIDENTS(state, payload) {
    state.activeIncidents = payload;
  },
  PREPLAN_INCIDENTS(state, payload) {
    state.preplanIncidents = payload;
  },
  ARCHIVED_INCIDENTS(state, payload) {
    state.archivedIncidents = payload;
  },
  CURRENT_LAYER(state, payload) {
    state.currentLayer = payload;
  },
  UPDATE_CURRENT_INCIDENT(state, payload) {
    state.currentIncident = structuredClone(payload);
  },
  // Updates an item on active incidents list
  UPDATE_ACTIVE_INCIDENTS_ITEM(state, payload) {
    const itemIndex = state.activeIncidents?.findIndex((incident) => incident.id === payload.id);
    if (typeof itemIndex !== 'undefined') state.activeIncidents[itemIndex] = payload;
  },
  // Updates an item on preplan incidents list
  UPDATE_PREPLAN_INCIDENTS_ITEM(state, payload) {
    const itemIndex = state.preplanIncidents?.findIndex((incident) => incident.id === payload.id);
    if (typeof itemIndex !== 'undefined') state.preplanIncidents[itemIndex] = payload;
  },
  // Updates an item on archived incidents list
  UPDATE_ARCHIVED_INCIDENTS_ITEM(state, payload) {
    const itemIndex = state.archivedIncidents?.findIndex((incident) => incident.id === payload.id);
    if (typeof itemIndex !== 'undefined') state.archivedIncidents[itemIndex] = payload;
  },
  SORT_INCIDENTS_BY_HIGH_PRIORITY(state) {
    state.activeIncidents.sort((a, b) => (b.priority_incident - a.priority_incident));
  },
};

export const actions = {
  async loadIncident({ commit }, { user, incident_id } = {}) {
    const { client_id } = user;
    if (client_id) {
      const response = await axios.get(
        `/api/json/v1/client/${client_id}/incidents/${incident_id}`,
      );
      if (response) {
        commit('CURRENT_INCIDENT', response.data.incident);

        // Update the incident in the appropriate list
        if (response.data.incident.preplan === false && response.data.incident.archived === false) {
          commit('UPDATE_ACTIVE_INCIDENTS_ITEM', response.data.incident);
        } else if (response.data.incident.archived === true) {
          commit('UPDATE_ARCHIVED_INCIDENTS_ITEM', response.data.incident);
        } else {
          commit('UPDATE_PREPLAN_INCIDENTS_ITEM', response.data.incident);
        }
      }
      return;
    }
    await showError();
  },
  async loadActiveIncidents({ commit }, { user } = {}) {
    const { client_id } = user;
    if (client_id) {
      const activeIncidents = await axios.get(
        `/api/json/v1/client/${client_id}/incidents?limit=50&coordinates=false`,
      );
      if (activeIncidents) {
        commit('ACTIVE_INCIDENTS', activeIncidents.data.incidents);
        commit('SORT_INCIDENTS_BY_HIGH_PRIORITY');
      }
      return;
    }
    await showError();
  },
  async loadPreplanIncidents({ commit }, { user } = {}) {
    const { client_id } = user;
    if (client_id) {
      const preplanIncidents = await axios.get(
        `/api/json/v1/client/${client_id}/incidents?type=preplan&limit=50&coordinates=false`,
      );
      if (preplanIncidents) {
        commit('PREPLAN_INCIDENTS', preplanIncidents.data.incidents);
      }
      return;
    }
    await showError();
  },
  async loadArchivedIncidents({ commit }, { user } = {}) {
    const { client_id } = user;
    if (client_id) {
      const archivedIncidents = await axios.get(
        `/api/json/v1/client/${client_id}/incidents?type=archive&limit=50&coordinates=false`,
      );
      if (archivedIncidents) {
        commit('ARCHIVED_INCIDENTS', archivedIncidents.data.incidents);
      }
      return;
    }
    await showError();
  },
  async updateIncident({ state }, { user, incident_id } = {}) {
    // This will update the incident in the database (patch to the backend API)
    const { client_id } = user;

    showLoading('Saving incident...');

    if (client_id) {
      // Sanitize data to update only Incident Basic Data
      const basicDataFields = [
        'map_icon',
        'name',
        'custom_id',
        'priority_incident',
        'location',
        // Check coordinates update endpoint
        // 'latitude',
        // 'longitude',
        'date',
        'time',
        'time_zone',
        'description',
        // Triggers tab fields
        'cad_triggers',
        'cad_nature',
        'rave_campus',
        'rave_building',
        'rave_type',
      ];

      const data = {};
      basicDataFields.forEach((field) => {
        data[field] = state.currentIncident[field];
      });

      const updateUrl = `/api/json/v1/client/${client_id}/incidents/${incident_id}`;

      await axios.patch(updateUrl, data)
        .then((response) => {
          handleOkResponse({
            response,
            textSuccess: 'Incident has been saved!',
          });
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      await showError();
    }
  },
  // eslint-disable-next-line no-empty-pattern,consistent-return
  launchNewIncidentFromData({}, { user, launchData } = {}) {
    const { client_id } = user;
    showLoading('Launching New Incident <br>from Data...');
    if (client_id) {
      const postUrl = `/api/json/v1/client/${client_id}/incidents/launch`;
      // eslint-disable-next-line no-return-await
      return axios.post(postUrl, launchData)
        .then((response) => {
          handleOkResponse({
            response,
            textSuccess: 'Incident has been created!',
          });
          return response.data.incident_id;
        })
        .catch((error) => {
          handleErrorResponse(error);
          return error.response;
        });
    }
    showError();
  },
  // eslint-disable-next-line no-empty-pattern,consistent-return
  launchNewIncidentFromPreplan({}, { user, incident_id } = {}) {
    const { client_id } = user;
    showLoading('Launching New Incident <br>from Preplan...');
    if (client_id) {
      const postUrl = `/api/json/v1/client/${client_id}/incidents/launch/${incident_id}`;
      // eslint-disable-next-line no-return-await
      return axios.post(postUrl)
        .then((response) => {
          handleOkResponse({
            response,
            textSuccess: 'Incident has been created!',
          });
          return response.data.incident_id;
        })
        .catch((error) => {
          handleErrorResponse(error);
          return error.response;
        });
    }
    showError();
  },
  // eslint-disable-next-line no-empty-pattern,consistent-return
  archiveIncident({ commit }, { user, incident_id } = {}) {
    const { client_id } = user;
    showLoading('Closing This Incident...');
    if (client_id) {
      const postUrl = `/api/json/v1/client/${client_id}/incidents/${incident_id}/archive`;
      return axios.post(postUrl)
        .then((response) => {
          handleOkResponse({
            response,
            textSuccess: 'Incident has been archived!',
          });
          // Remove the Archived incident from the Active Incidents state.
          const updated_incidents = state.activeIncidents
            .filter((incident) => incident.id !== incident_id);
          commit('ACTIVE_INCIDENTS', updated_incidents);
          return Promise.resolve(response.data.incident_id);
        })
        .catch((error) => {
          handleErrorResponse(error);
          console.log('error: ', error);
          return Promise.reject(error.response);
        });
    }
    showError();
  },
  setActiveIncidents({ commit }, payload) {
    commit('ACTIVE_INCIDENTS', payload);
  },
  setArchivedIncidents({ commit }, payload) {
    commit('ARCHIVED_INCIDENTS', payload);
  },
  setPreplanIncidents({ commit }, payload) {
    commit('PREPLAN_INCIDENTS', payload);
  },
  setCurrentIncident({ commit }, payload) {
    commit('CURRENT_INCIDENT', payload);
  },
  updateCurrentIncident({ commit }, payload) {
    commit('UPDATE_CURRENT_INCIDENT', payload);
  },
};
