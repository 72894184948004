import axios from 'axios';
import {
  closeLoading,
  handleErrorResponse,
  showError, showLoading,
} from '@/components/widgets/swalUtils';

export const state = {
  clientTags: null,
  incidentCurrentTags: null,
  incidentNewTags: null,
};

export const getters = {
  getClientTags(state) {
    return state.clientTags;
  },
  getIncidentCurrentTags(state) {
    return state.incidentCurrentTags;
  },
  getIncidentNewTags(state) {
    return state.incidentNewTags;
  },
};

export const mutations = {
  SET_CLIENT_TAGS(state, payload) {
    state.clientTags = payload;
  },
  SET_INCIDENT_CURRENT_TAGS(state, payload) {
    state.incidentCurrentTags = payload;
  },
  SET_INCIDENT_NEW_TAGS(state, payload) {
    state.incidentNewTags = payload;
  },
};

export const actions = {
  async loadClientTags({ commit }, { user } = {}) {
    const { client_id } = user;
    if (client_id) {
      // Gets all active Tags for a given client
      await axios.get(`/api/json/v1/client/${client_id}/tags`)
        .then((response) => {
          if (response.status === 200) {
            const clientTags = response.data.tags;
            clientTags.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));
            commit('SET_CLIENT_TAGS', clientTags);
          } else {
            showError();
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      await showError();
    }
  },

  async updateIncidentTags({ state, commit, dispatch }, { user, incident_id } = {}) {
    const { client_id } = user;

    showLoading('Updating Tags...');

    // Filter out tags to add and remove to/from incident
    const toAdd = state.incidentNewTags
      .filter((tag) => !state.incidentCurrentTags.includes(tag));
    const toRemove = state.incidentCurrentTags
      .filter((tag) => !state.incidentNewTags.includes(tag));

    const updateTagsRequests = [];
    toAdd.forEach((id) => {
      updateTagsRequests.push({ method: 'patch', id });
    });
    toRemove.forEach((id) => {
      updateTagsRequests.push({ method: 'delete', id });
    });

    for (let i = 0; i < updateTagsRequests.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await axios({
        method: updateTagsRequests[i].method,
        url: `/api/json/v1/client/${client_id}/incidents/`
          + `${incident_id}/tags/${updateTagsRequests[i].id}`,
      }).then((response) => {
        if (response.status !== 200) {
          showError();
          console.log('Response: ', response);
        }
      }).catch((error) => {
        handleErrorResponse(error);
        console.log('Error: ', error);
        console.log('Error Response: ', error.response);
      });
    }

    closeLoading();
    // Update store Current Tags with new Tags
    commit('SET_INCIDENT_CURRENT_TAGS', state.incidentNewTags);
    // Reload incident with new Tags attached
    dispatch('incidents/loadIncident', { user, incident_id }, { root: true });
  },

  setIncidentCurrentTags({ commit }, payload) {
    commit('SET_INCIDENT_CURRENT_TAGS', payload);
  },

  setIncidentNewTags({ commit }, payload) {
    commit('SET_INCIDENT_NEW_TAGS', payload);
  },
};
