import axios from 'axios';
import {
  closeLoading,
  handleErrorResponse,
  showError, showLoading,
  askConfirmation, showMessage,
  inputText,
} from '@/components/widgets/swalUtils';
import {
  allowedFilesToUpload,
  noHeaderFileTypes,
} from '@/components/widgets/filesUtils';

export const state = {
  incidentFiles: null,
  incidentFilesToUpdate: [],
  incidentFilesToDelete: [],
};

export const getters = {
  getIncidentFiles(state) {
    return state.incidentFiles;
  },
};

export const mutations = {
  SET_INCIDENT_FILES(state, payload) {
    state.incidentFiles = payload;
  },
  SET_INCIDENT_FILES_TO_UPDATE(state, payload) {
    state.incidentFilesToUpdate = payload;
  },
  SET_INCIDENT_FILES_TO_DELETE(state, payload) {
    state.incidentFilesToDelete = payload;
  },
  TOGGLE_SHARED_PORTAL_INCIDENT_FILE(state, payload) {
    const file = state.incidentFiles.find((file) => file.id === payload);
    file.shared_portal = !file.shared_portal;
    if (!state.incidentFilesToUpdate.includes(file.id)) state.incidentFilesToUpdate.push(file.id);
  },
  EDIT_NAME_INCIDENT_FILE(state, payload) {
    const file = state.incidentFiles.find((file) => file.id === payload.id);
    file.file_name = payload.name;
    if (!state.incidentFilesToUpdate.includes(file.id)) state.incidentFilesToUpdate.push(file.id);
  },
  DELETE_INCIDENT_FILE(state, file_id) {
    state.incidentFiles = state.incidentFiles.filter((file) => file.id !== file_id);
    if (!state.incidentFilesToDelete.includes(file_id)) state.incidentFilesToDelete.push(file_id);
  },
};

export const actions = {
  async loadIncidentFiles({ commit }, { user, incident_id } = {}) {
    const { client_id } = user;
    if (client_id) {
      // Get Files related to this Incident
      await axios.get(`/api/json/v1/client/${client_id}/incidents/${incident_id}/files`)
        .then((response) => {
          if (response.status === 200) {
            const { files } = response.data;
            files.sort(
              (a, b) => (a.file_name.toLowerCase() > b.file_name.toLowerCase() ? 1 : -1),
            );
            commit('SET_INCIDENT_FILES', files);
          } else {
            showError('Unable to load files!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async getIncidentFileUrl({}, { user, incident_id, file }) {
    const { client_id } = user;
    let fileURL;
    if (client_id) {
      showLoading('Opening File...');

      await axios.get(`/api/json/v1/client/${client_id}/incidents/${incident_id}/files/${file.id}/url`)
        .then((response) => {
          if (response.status === 200) {
            fileURL = response.data.url;
            closeLoading();
          } else {
            showError('Unable to locate file!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
    }
    return fileURL;
  },

  // eslint-disable-next-line no-empty-pattern
  downloadIncidentFile({}, { user, incident_id, file }) {
    const { client_id } = user;
    let fileURL;
    if (client_id) {
      showLoading('Downloading File...');
      axios.get(`/api/json/v1/client/${client_id}/incidents/${incident_id}/files/${file.id}/url`)
        .then((response) => {
          if (response.status === 200) {
            fileURL = response.data.url;
            window.open(fileURL, '_blank');
            closeLoading();
          } else {
            showError('Unable to load files!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          closeLoading();
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
    }
    return fileURL;
  },

  uploadIncidentFile({ commit }, {
    user, incident_id, formData, fileHeader,
  } = {}) {
    const { client_id } = user;
    if (client_id) {
      // Check if file was sent from UI
      if (formData.get('file') === 'null') {
        showMessage({
          title: 'Please choose file to upload!',
          text: '',
          icon: 'warning',
          timer: 3000,
        });
        return;
      }

      // Check if file header match file extension and is valid MIME type to upload
      // Get File and File Extension
      const file = formData.get('file');
      const fileExtension = file.name.split('.').pop();
      // Check if file has no pattern defined (txt, csv, etc..)
      const allowedHeaders = [];
      allowedFilesToUpload.forEach((type) => {
        if (type.header !== '') {
          allowedHeaders.push(type.header);
        }
      });

      // Check if this file type can be uploaded (extension check)
      const typeOfFileToUpload = allowedFilesToUpload
        .find((type) => type.extension === fileExtension);
      if (!typeOfFileToUpload) {
        showError(`This type of file cannot be uploaded!
        ${fileExtension.toUpperCase()} file is not allowed!`);
        return;
      }

      // Avoid file which extension was changed
      // File extension is noHeaderFileType but file header match another type.
      if (noHeaderFileTypes.includes(fileExtension) && allowedHeaders.includes(fileHeader)) {
        // This file is not supposed to have a defined known header pattern
        // Extension is No header (csv, txt)
        // File Header is known in the allowed types
        showError('File has been corrupted!');
        return;
      }

      // Check file extension matches file header
      if (!noHeaderFileTypes.includes(fileExtension)
        && !fileHeader.includes(typeOfFileToUpload.header)) {
        // Extension is not No header (pdf, xlsx, jpeg)
        // File Header doesn't match with file extension
        showError('File has been corrupted!');
        return;
      }

      // Uploading File.
      showLoading('Uploading File...');

      axios.post(
        `/api/json/v1/client/${client_id}/incidents/${incident_id}/files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
        .then((response) => {
          if (response.status === 200) {
            // Reloading Files to Vuex State
            axios.get(`/api/json/v1/client/${client_id}/incidents/${incident_id}/files`)
              .then((response) => {
                if (response.status === 200) {
                  const { files } = response.data;
                  files.sort(
                    (a, b) => (a.file_name.toLowerCase() > b.file_name.toLowerCase() ? 1 : -1),
                  );
                  commit('SET_INCIDENT_FILES', files);
                  closeLoading();
                  showMessage({
                    title: 'File Uploaded!',
                  });
                } else {
                  showError('Unable to load files!');
                  console.log('Response: ', response);
                }
              })
              .catch((error) => {
                handleErrorResponse(error);
              });
          } else {
            showError('Unable to upload file!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          closeLoading();
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
    }
  },

  async updateIncidentFiles({ state, dispatch, commit }, { user, incident_id } = {}) {
    const { client_id } = user;

    showLoading('Updating Files...');

    const requestsArray = [];
    // Push Update promises to array
    state.incidentFilesToUpdate.forEach((file_id) => {
      const patchURL = `/api/json/v1/client/${client_id}/incidents/${incident_id}`
        + `/files/${file_id}`;
      const fileToUpdate = state.incidentFiles.find((file) => file.id === file_id);
      const body = {
        shared: fileToUpdate.shared_portal,
        file_name: fileToUpdate.file_name,
      };
      requestsArray.push(axios.patch(patchURL, body));
    });

    // Push Delete promises to array
    state.incidentFilesToDelete.forEach((file_id) => {
      const deleteURL = `/api/json/v1/client/${client_id}/incidents/${incident_id}`
        + `/files/${file_id}`;
      requestsArray.push(axios.delete(deleteURL));
    });

    Promise
      .all(requestsArray)
      .then(axios.spread((...responses) => {
        responses.forEach((response) => {
          if (response.status !== 200) {
            showError();
            console.log('Response: ', response);
          }
        });
        // Reload incident with new checklists attached
        dispatch('incidents/loadIncident', { user, incident_id }, { root: true });
        closeLoading();
        commit('SET_INCIDENT_FILES_TO_DELETE', []);
        commit('SET_INCIDENT_FILES_TO_UPDATE', []);
      }))
      .catch((error) => {
        handleErrorResponse(error);
        console.log('Error: ', error);
        console.log('Error Response: ', error.response);
      });
  },

  async editNameIncidentFile({ commit }, payload) {
    const currentName = payload.name.substring(0, payload.name.lastIndexOf('.'));
    const extension = payload.name.split('.').pop();
    let newName = '';
    await inputText({
      title: 'Enter new file name:',
      currentValue: currentName,
    }).then((response) => {
      if (response.isConfirmed && currentName !== response.value) {
        newName = response.value;
        payload.name = `${newName}.${extension}`;
        commit('EDIT_NAME_INCIDENT_FILE', payload);
      }
    });
  },

  async deleteIncidentFile({ commit }, payload) {
    await askConfirmation({
      title: 'Do you want to delete this file?',
    }).then((result) => {
      if (result.isConfirmed) {
        commit('DELETE_INCIDENT_FILE', payload);
        showMessage({
          title: 'Deleted!',
          text: 'Save your changes.',
        });
      }
    });
  },

  setIncidentFiles({ commit }, payload) {
    commit('SET_INCIDENT_FILES', payload);
  },

  toggleSharedPortalIncidentFile({ commit }, payload) {
    commit('TOGGLE_SHARED_PORTAL_INCIDENT_FILE', payload);
  },

  cleanStateFiles({ commit }) {
    commit('SET_INCIDENT_FILES', null);
    commit('SET_INCIDENT_FILES_TO_UPDATE', []);
    commit('SET_INCIDENT_FILES_TO_DELETE', []);
  },
};
