import axios from 'axios';
import {
  showError, handleErrorResponse, showMessage,
} from '@/components/widgets/swalUtils';

export const state = {
  search: '',
  currentPage: 1,
  itemsPerPage: 5,
  loading: false,
  tasks: [],
  currentIncident: null,
  currentTask: null,
  listenMapLocation: false,
};

export const getters = {
  getListenMapLocationStatus(state) {
    return state.listenMapLocation;
  },
  getCurrentTask(state) {
    return state.currentTask;
  },
  getLoadingTasksStatus(state) {
    return state.loading;
  },
  getTasks(state) {
    return state.tasks;
  },
  getTasksPaginated(state) {
    const tasks = state.tasks.filter(
      (item) => item.incident_id === state.currentIncident.id,
    );

    return tasks.slice(
      (state.currentPage - 1) * state.itemsPerPage,
      state.currentPage * state.itemsPerPage,
    );
  },
  getTasksCurrentPage(state) {
    return state.currentPage;
  },
  getTasksTotalPages(state) {
    if (state.currentIncident?.id) {
      return (state.tasks.filter(
        (item) => item.incident_id === state.currentIncident.id,
      )
        .length / state.itemsPerPage).toFixed(0);
    }
    return (state.tasks.length / state.itemsPerPage).toFixed(0);
  },
  getIncidentTasks(state) {
    return state.tasks.filter(
      (item) => item.incident_id.includes(state.currentIncident.id),
    );
  },
};

export const mutations = {
  SWITCH_CLICK_LOCATION_STATUS(state, payload) {
    state.listenMapLocation = payload;
  },
  SWITCH_LOADING_STATUS(state) {
    state.loading = !state.loading;
  },
  SET_TASKS(state, payload) {
    Object.keys(payload).forEach(
      (index) => state.tasks.push(payload[index]),
    );
  },
  CLEAR_TASKS(state) {
    state.tasks = [];
  },
  UPDATE_SEARCH(state, payload) {
    state.search = payload;
  },
  NEXT_PAGE(state) {
    state.currentPage += 1;
  },
  PREVIOUS_PAGE(state) {
    state.currentPage -= 1;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_CURRENT_INCIDENT(state, payload) {
    state.currentIncident = payload;
  },
  SET_CURRENT_TASK(state, payload) {
    state.currentTask = payload;
  },
};

export const actions = {
  switchClickLocationStatus({ commit }, { status } = {}) {
    commit('SWITCH_CLICK_LOCATION_STATUS', status);
  },
  setCurrentTask({ commit }, { task } = {}) {
    commit('SET_CURRENT_TASK', task);
  },
  setTasksCurrentIncident({ commit }, incident) {
    commit('SET_CURRENT_INCIDENT', incident);
  },
  pageControl({ commit, getters }, { action, page } = {}) {
    switch (action) {
      case 'next':
        if (getters.getTasksCurrentPage < getters.getTasksTotalPages) {
          commit('NEXT_PAGE');
        }
        break;
      case 'previous':
        if (getters.getTasksCurrentPage > 1) {
          commit('PREVIOUS_PAGE');
        }
        break;
      case 'goto': commit('SET_CURRENT_PAGE', page);
        break;
      default: break;
    }
  },
  updateSearch({ commit }, { text } = {}) {
    commit('UPDATE_SEARCH', text);
  },
  async loadOffsetTasks({ commit, state, dispatch }, { user, offset } = {}) {
    offset = !offset ? 50 : offset += 50;

    if (!state.loading) {
      commit('SWITCH_LOADING_STATUS');
    }

    if (state.tasks?.length === offset) {
      const { client_id } = user;
      if (client_id) {
        axios.get(`/api/json/v1/client/${client_id}/tasks?offset=${offset}`)
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.tasks) {
                commit('SET_TASKS', response.data.tasks);
                dispatch('loadOffsetTasks', {
                  user, offset,
                });
              }
            } else {
              showError('Unable to load tasks!');
              console.log('Response: ', response);
            }
          })
          .catch((error) => {
            handleErrorResponse(error);
          });
      } else {
        showError('Client not found!');
      }
    } else {
      commit('SWITCH_LOADING_STATUS');
    }
  },
  async loadTasks({ commit, dispatch }, { user, invalidate, loadFull } = {}) {
    if (!state.loading) {
      commit('SWITCH_LOADING_STATUS');
    }

    if (invalidate) {
      commit('CLEAR_TASKS');
    }

    const { client_id } = user;
    if (client_id) {
      axios.get(`/api/json/v1/client/${client_id}/tasks`)
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.tasks) {
              commit('SET_TASKS', response.data.tasks);
              if (loadFull) {
                dispatch('loadOffsetTasks', { user });
              } else {
                commit('SWITCH_LOADING_STATUS');
              }
            }
          } else {
            showError('Unable to load tasks!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
      commit('SWITCH_LOADING_STATUS');
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async createTask({}, { user, form, incident } = {}) {
    try {
      const { client_id } = user;
      const incident_id = incident.id;

      const data = {
        latitude: form.latitude,
        longitude: form.longitude,
        location: form.location,
        name: form.taskType.name,
        callsign: form.callsign,
        notes: form.notes,
        alert_time: form.alert,
        alert: !!form.alert,
      };

      if (form?.id) {
        await axios.patch(`/api/json/v1/client/${client_id}/incident/${incident_id}/tasks/${form.id}`, data).then(
          () => showMessage({ title: '', timer: 1000 }),
        ).catch((error) => {
          handleErrorResponse(error);
        });
      } else {
        await axios.post(`/api/json/v1/client/${client_id}/incident/${incident_id}/tasks`, data).then(
          () => showMessage({ title: '', timer: 1000 }),
        ).catch((error) => {
          handleErrorResponse(error);
        });
      }
    } catch (e) {
      showError({ title: 'Error saving task', text: e.message });
    }
  },
};
