import axios from 'axios';
import {
  showError,
  handleErrorResponse,
  showLoading,
  showMessage, handleOkResponse,
} from '@/components/widgets/swalUtils';

export const state = {
  filters: {
    search: '',
    connectedAccounts: false,
    showGPS: false,
  },
  loading: false,
  clientUnits: [],
};

export const getters = {
  getLoadingUnitsStatus(state) {
    return state.loading;
  },
  getClientUnits(state) {
    return state.clientUnits;
  },
  getClientStaggedUnits(state) {
    if (state.filters.search.length > 3) {
      if (state.filters.showGPS) {
        return state.clientUnits.filter(
          (item) => item.status === 'On-Scene'
            && item.name.includes(state.filters.search)
            && item.coordinates?.latitude
            && item.coordinates?.longitude,
        );
      }
      return state.clientUnits.filter(
        (item) => item.status === 'On-Scene' && item.name.includes(state.filters.search),
      );
    }

    if (state.filters.showGPS) {
      return state.clientUnits.filter(
        (item) => item.status === 'On-Scene'
          && item.coordinates?.latitude
          && item.coordinates?.longitude,
      );
    }
    return state.clientUnits.filter(
      (item) => item.status === 'On-Scene',
    );
  },
  getClientAvailableUnits(state) {
    if (state.filters.search.length > 3) {
      if (state.filters.showGPS) {
        return state.clientUnits.filter(
          (item) => item.status === 'Available'
            && item.name.includes(state.filters.search)
            && item.coordinates?.latitude
            && item.coordinates?.longitude,
        );
      }
      return state.clientUnits.filter(
        (item) => item.status === 'Available' && item.name.includes(state.filters.search),
      );
    }

    if (state.filters.showGPS) {
      return state.clientUnits.filter(
        (item) => item.status === 'Available'
          && item.coordinates?.latitude
          && item.coordinates?.longitude,
      );
    }
    return state.clientUnits.filter(
      (item) => item.status === 'Available',
    );
  },
  getClientRespondingUnits(state) {
    if (state.filters.search.length > 3) {
      if (state.filters.showGPS) {
        return state.clientUnits.filter(
          (item) => item.status === 'Responding'
            && item.name.includes(state.filters.search)
            && item.coordinates?.latitude
            && item.coordinates?.longitude,
        );
      }
      return state.clientUnits.filter(
        (item) => item.status === 'Responding' && item.name.includes(state.filters.search),
      );
    }

    if (state.filters.showGPS) {
      return state.clientUnits.filter(
        (item) => item.status === 'Responding'
          && item.coordinates?.latitude
          && item.coordinates?.longitude,
      );
    }
    return state.clientUnits.filter(
      (item) => item.status === 'Responding',
    );
  },
  getSelectedUnits(state) {
    return state.clientUnits.filter(
      (item) => item.selected === true,
    );
  },
};

export const mutations = {
  SWITCH_LOADING_STATUS(state) {
    state.loading = !state.loading;
  },
  SET_CLIENT_UNITS(state, payload) {
    Object.keys(payload).forEach(
      (index) => state.clientUnits.push(payload[index]),
    );
  },
  CLEAR_CLIENT_UNITS(state) {
    state.clientUnits = [];
  },
  SET_SELECTED_UNIT(state, payload) {
    const unit = state.clientUnits.filter(
      (item) => item.id === payload?.id,
    );
    if (unit) {
      unit[0].selected = !unit[0].selected;
    }
  },
  UPDATE_SEARCH(state, payload) {
    state.filters.search = payload;
  },
  CLEAR_UNITS_SELECTION(state) {
    Object.keys(state.clientUnits).forEach((key) => {
      if (state.clientUnits[key]?.selected) {
        delete state.clientUnits[key].selected;
      }
    });
  },
  UPDATE_UNIT(state, payload) {
    Object.keys(state.clientUnits).forEach((index) => {
      if (state.clientUnits[index].id === payload.id) {
        state.clientUnits[index] = payload;
      }
    });
  },
  SWITCH_LOCATION_FILTER(state, payload) {
    state.filters.showGPS = payload;
  },
  SWITCH_ACCOUNTS_FILTER(state, payload) {
    state.filters.connectedAccounts = payload;
  },
};

export const actions = {
  setUnitFilter({ commit }, { showGPS, connectedAccounts } = {}) {
    commit('SWITCH_LOCATION_FILTER', !!showGPS);
    commit('SWITCH_ACCOUNTS_FILTER', !!connectedAccounts);
  },
  // eslint-disable-next-line no-unused-vars
  async createUnit({ state }, { user, newUnitData } = {}) {
    // This will create the personnel in the database (post to the backend API)
    const { client_id } = user;
    // return;
    showLoading('Adding Unit...');
    if (client_id) {
      const postUrl = `/api/json/v1/client/${client_id}/units`;

      Object.keys(newUnitData).forEach((key) => {
        if (newUnitData[key] === '') {
          delete newUnitData[key];
        }
      });

      await axios.post(postUrl, newUnitData)
        .then((response) => {
          handleOkResponse({
            response,
            textSuccess: 'Unit has been added!',
          });
          /*
          // Assign incident to Unit and change unit status
          if (response.data.unit_id) {
            const { unit_id } = response.data;
            // showLoading('Setting Unit to On-Scene...');
            // need to bring the incident id from somewhere
            const UnitOnSceneUrl = `/api/json/v1/client/${client_id}/units/${unit_id}/status`;
            const UnitOnSceneData = {
              status: 'On-Scene',
              incident_id: incident_id,
            }
            axios.post(UnitOnSceneUrl, UnitOnSceneData).then((response) => {
              handleOkResponse({
                response,
                textSuccess: 'Incident has been assigned to Unit!',
              });
            });
          } */
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      await showError();
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async updateUnitsStatus({ getters, commit }, { status, user, incident } = {}) {
    try {
      showLoading('Updating Units');

      const selectedUnits = getters.getSelectedUnits;

      const { client_id } = user;

      for (let index = 0; index < selectedUnits.length; index += 1) {
        const unit_id = selectedUnits[index].id;
        if (status === 'Responding' || status === 'On-Scene') {
          // eslint-disable-next-line no-await-in-loop
          const response = await axios.post(
            `/api/json/v1/client/${client_id}/units/${unit_id}/status`,
            {
              status,
              incident_id: incident.id,
            },
          ).catch((error) => {
            handleErrorResponse(error);
          });

          if (response.data?.unit_id === selectedUnits[index].id) {
            commit('UPDATE_UNIT', { ...selectedUnits[index], status });
          }
        } else {
          // eslint-disable-next-line no-await-in-loop
          const response = await axios.post(`/api/json/v1/client/${client_id}/units/${unit_id}/status`, { status }).catch((error) => {
            handleErrorResponse(error);
          });

          if (response.data?.unit_id === selectedUnits[index].id) {
            commit('UPDATE_UNIT', { ...selectedUnits[index], status });
          }
        }
      }
      showMessage({ title: null, icon: 'success', timer: 1000 });
      return Promise.resolve(true);
    } catch (e) {
      showError({ text: e.message });
      return Promise.resolve(false);
    }
  },
  clearUnitsSelection({ commit }) {
    commit('CLEAR_UNITS_SELECTION');
  },
  updateSearch({ commit }, { text } = {}) {
    commit('UPDATE_SEARCH', text);
  },
  setSelectedUnit({ commit }, { unit } = {}) {
    commit('SET_SELECTED_UNIT', unit);
  },
  async loadOffsetClientUnits({ commit, state, dispatch }, { user, offset } = {}) {
    offset = !offset ? 50 : offset += 50;

    if (!state.loading) {
      commit('SWITCH_LOADING_STATUS');
    }

    if (state.clientUnits?.length === offset) {
      const { client_id } = user;
      if (client_id) {
        await axios.get(`/api/json/v1/client/${client_id}/units?offset=${offset}`)
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.units) {
                commit('SET_CLIENT_UNITS', response.data.units);
                dispatch('loadOffsetClientUnits', {
                  user, offset,
                });
              }
            } else {
              showError('Unable to load units!');
              console.log('Response: ', response);
            }
          })
          .catch((error) => {
            handleErrorResponse(error);
          });
      } else {
        showError('Client not found!');
      }
    } else {
      commit('SWITCH_LOADING_STATUS');
    }
  },
  async loadClientUnits({ commit, dispatch }, { user, invalidate, loadFull } = {}) {
    if (!state.loading) {
      commit('SWITCH_LOADING_STATUS');
    }

    if (invalidate) {
      commit('CLEAR_CLIENT_UNITS');
    }

    const { client_id } = user;
    if (client_id) {
      await axios.get(`/api/json/v1/client/${client_id}/units`)
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.units) {
              commit('SET_CLIENT_UNITS', response.data.units);
              if (loadFull) {
                dispatch('loadOffsetClientUnits', { user });
              } else {
                commit('SWITCH_LOADING_STATUS');
              }
            }
          } else {
            showError('Unable to load units!');
            console.log('Response: ', response);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        });
    } else {
      showError('Client not found!');
      commit('SWITCH_LOADING_STATUS');
    }
  },
};
