import { createApp } from 'vue';

// Font awesome
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from 'vue3-apexcharts';
import vClickOutside from 'click-outside-vue3';
import { registerScrollSpy } from 'vue3-scroll-spy';

import Maska from 'maska';
import './axios';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

// Leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-toolbar/dist/leaflet.toolbar.css';
import 'leaflet-geometryutil';
import 'leaflet.gridlayer.googlemutant';

import store from './state/store';

import './design/app.scss';

import i18n from './i18n';
import router from './router';
import App from './App.vue';

// Font awesome importing and watching class to replace for icon.
library.add(fas, far, fab);
dom.watch();

createApp(App)
  .use(store)
  .use(router)
  .use(require('vue-chartist'))
  .use(BootstrapVue3)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .use(Maska)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
