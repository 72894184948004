import swal from 'sweetalert2';

export function showLoading(title = 'Loading...') {
  swal.fire({ title, allowOutsideClick: false });
  swal.showLoading();
}

export function closeLoading() {
  swal.close();
}

export function showMessage({
  title = 'Loading...',
  text = '',
  icon = 'success',
  timer = 3000,
}) {
  swal.fire({
    title,
    text,
    icon,
    timer,
    showConfirmButton: false,
  });
}

export function askConfirmation({
  title = 'Are you sure?',
  icon = 'warning',
}) {
  return swal.fire({
    title,
    icon,
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: 'Yes',
    denyButtonText: 'No',
    allowOutsideClick: false,
  });
}

// Special alert used only on user Logout prompt
export function logOutMessage() {
  return swal.fire({
    title: 'Are you sure?',
    showCancelButton: true,
    icon: 'info',
    confirmButtonText: 'Yes',
  });
}

export function showError(errorText = 'Something went wrong!') {
  swal.fire({
    icon: 'error',
    title: 'Error',
    text: errorText,
    allowOutsideClick: false,
  });
}

export function handleOkResponse({
  response,
  textSuccess,
}) {
  if (response.status === 200) {
    swal.fire({
      icon: 'success',
      title: 'Success',
      text: textSuccess,
      allowOutsideClick: false,
    });
  } else {
    swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Something went wrong!',
      allowOutsideClick: false,
    });
  }
}

export function handleErrorResponse(error) {
  let errorMessage;
  switch (error.response.status) {
    case 400:
    case 403:
      errorMessage = error.response.data.message;
      break;
    case 404:
      errorMessage = error.response.statusText;
      break;
    default:
      errorMessage = 'Unknown Error';
  }
  swal.fire({
    icon: 'error',
    title: `Error ${error.response.status}`,
    text: errorMessage,
  });
}

// Input Prompts

export function inputText({
  title = 'Enter value',
  currentValue,
}) {
  return swal.fire({
    title,
    input: 'text',
    inputValue: currentValue,
    showCancelButton: true,
    inputValidator: (input) => {
      input = input.trim();
      if (!input) {
        return 'Please give an input!';
      }
      return '';
    },
    allowOutsideClick: false,
  });
}
