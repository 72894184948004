import axios from 'axios';
import {
  showError,
} from '@/components/widgets/swalUtils';

export const state = {
  checklistItems: null,
};

export const getters = {
  getChecklistItems(state) {
    return state.checklistItems;
  },
};

export const mutations = {
  CHECKLISTSITEMS(state, payload) {
    state.checklistItems = payload;
  },
};

export const actions = {
  async loadChecklistItems({ commit }, { user } = {}) {
    const { client_id } = user;
    if (client_id) {
      // Checklists Items
      const response = await axios.get(
        `/api/json/v1/client/${client_id}/checklistitems`,
      );

      if (response) {
        commit('CHECKLISTSITEMS', response.data.checklist_items);
      }
      const myChecklistItems = response.data.checklist_items;
      if (myChecklistItems) {
        commit('CHECKLISTSITEMS', myChecklistItems);
      }

      return;
    }
    await showError();
  },
  setChecklistItems({ commit }, payload) {
    commit('checklistItems', payload);
  },
};
