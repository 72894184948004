export function fixedLng(lng) {
  return parseFloat(lng).toFixed(10);
}

export function convertPointsToCoordinates(points) {
  const location = [];

  if (points?.length === 1) {
    for (let index = 0; index < points[0].length; index += 1) {
      location.push(
        { x: points[0][index].lat, y: points[0][index].lng },
      );
    }
    return location;
  }

  if (points?.length > 1) {
    for (let index = 0; index < points.length; index += 1) {
      location.push(
        { x: points[index].lat, y: points[index].lng },
      );
    }
    return location;
  }

  location.push({ x: points.lat, y: points.lng });
  return location;
}

export function getCoordinates(layer, type) {
  let lineDistance = 0;

  if (type === 'marker' || type === 'landmark' || type === 'circle') {
    const { _latlng } = layer;
    return {
      lineDistance,
      lat: _latlng.lat,
      lng: _latlng.lng,
      coordinates: [_latlng],
    };
  }

  const coordinates = layer.getLatLngs();

  if (coordinates.length === 1) {
    for (let index = 0; index < coordinates[0].length; index += 1) {
      if (index < coordinates[0].length - 1) {
        const distance = coordinates[0][index].distanceTo(coordinates[0][index + 1]);
        lineDistance += parseFloat(distance);
      }
    }
    return {
      lineDistance,
      lat: coordinates[0][0].lat,
      lng: coordinates[0][0].lng,
      coordinates,
    };
  }
  for (let index = 0; index < coordinates.length; index += 1) {
    console.log(coordinates[index]);
    if (index < coordinates.length - 1) {
      const distance = coordinates[index].distanceTo(coordinates[index + 1]);
      lineDistance += parseFloat(distance);
    }
  }
  return {
    lineDistance,
    lat: coordinates[0].lat,
    lng: coordinates[0].lng,
    coordinates,
  };
}
