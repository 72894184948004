import axios from 'axios';
import {
  showError,
  showLoading,
  closeLoading,
} from '@/components/widgets/swalUtils';

export const state = {
  layers: [{ name: 'None', id: null }],
  commonLayers: null,
  displayLayers: null,
  currentLayer: [{ name: 'None', id: null }],
};

export const getters = {
  getCurrentLayer(state) {
    return state.currentLayer;
  },
  getLayers(state) {
    return state.layers;
  },
};

export const mutations = {
  async SET_CURRENT_LAYER(state, payload) {
    if (state.currentLayer) {
      state.currentLayer.push(payload);
    } else {
      state.currentLayer = [payload];
    }
  },
  SET_LAYERS(state, payload) {
    let layersFirstIndex = 1;
    Object.keys(payload).forEach((key) => {
      state.layers[layersFirstIndex] = payload[key];
      layersFirstIndex += 1;
    });
  },
  REMOVE_CURRENT_LAYER(state, payload) {
    if (state.currentLayer) {
      state.currentLayer.splice(payload, 1);
    }
  },
};

export const actions = {
  async loadLayers({ commit }, { user } = {}) {
    const { client_id } = user;
    if (client_id) {
      const layers = await axios.get(`/api/json/v1/client/${client_id}/layers`);
      if (layers) {
        await commit('SET_LAYERS', layers.data.layers);
      }
      return;
    }
    await showError();
  },
  async changeCurrentLayer({ commit, state }, { payload } = {}) {
    showLoading('Applying layers...');
    let found = false;
    if (state.currentLayer) {
      Object.keys(state.currentLayer).forEach((key) => {
        if (state.currentLayer[key]?.id === payload.id) {
          closeLoading();
          commit('REMOVE_CURRENT_LAYER', key);
          found = true;
        }
      });
    }
    if (!found) {
      await commit('SET_CURRENT_LAYER', payload);
    }
    closeLoading();
  },
};
